<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Receber PIX</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <div class="card">
          <div class="card-body" v-if="!docsOk">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-md">Antes de receber via PIX, é necessário verificar a conta, completando o seu cadastro.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 text-center">
                <router-link to="/pages/user" class="btn btn-primary">
                  <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-6" v-if="isNullOrEmpty(pixCharge.qrCode)">
                <div class="row justify-content-between align-items-center">
                  <div class="col">
                        <span class="h7 surtitle text-muted">
                          Gerar QR Code para cobrança
                        </span>
                  </div>
                  <div class="col-auto">
                    <img src="/img/icons/logo-pix-icone-64-green.png" alt="Pix" height="36">
                    <span class="badge badge-lg" :class="`badge-success`"> PIX </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                    <base-input label="Deseja que o valor seja FIXO ou LIVRE para ser definido pelo pagador?">
                      <div class="row">
                        <div class="col-md-4">
                          <base-radio name="false" class="mb-3" v-model="editableValue">
                            VALOR LIVRE
                          </base-radio>
                        </div>
                        <div class="col-md-4">
                          <base-radio name="true" class="mb-3" v-model="editableValue">
                            VALOR FIXO
                          </base-radio>
                        </div>
                      </div>
                    </base-input>
                  </div>
                </div>
                <div class="row mt-2" v-if="editableValue">
                  <div class="col-md-4">
                    <base-input label="Valor"
                                placeholder="0,00"
                                type="text"
                                name="Valor"
                                :rules="{max:14}"
                                v-money="money"
                                v-model="chargeToCreate.value">
                      <span slot="prepend">R$</span>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      type="text"
                      label="Identificação no Extrato"
                      name="Identificação no Extrato"
                      placeholder="Opcional..."
                      :rules="{max: 30}"
                      v-model="chargeToCreate.userComment">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      type="text"
                      label="Mensagem ao Pagador"
                      name="Mensagem ao Pagador"
                      inputClasses="text-uppercase"
                      :placeholder="freeFieldDefaultMsg"
                      :rules="{max: freeFieldMaxLength, alpha_num_space: true}"
                      v-model="chargeToCreate.freeField">
                    </base-input>
                  </div>
                </div>
                <br/>
                <div class="row text-center">
                  <div class="col-md-12 mb-3">
                    <button type="button" class="btn btn-primary" v-bind:disabled="generatePix"
                            @click.prevent="handleSubmit(callCreateChargeWs)" >
                      <span class="btn-label"><i class="fa fa-arrow-up"></i></span> Gerar Cobrança
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="!isNullOrEmpty(pixCharge.qrCode)">
                <div class="card">
                  <div class="card-body">
                    <div class="row justify-content-between align-items-center">
                      <div class="col">
                        <div class="row">
                          <div class="col-md-6 text-center">
                            <button @click="printPdf(null)" class="btn btn-primary">
                              <i class="fas fa-print"/> Exibir PDF / Imprimir
                            </button>
                          </div>
                          <div class="col-md-6 text-center">
                            <button @click="clearAll()" class="btn btn-dark">
                              <img src="/img/icons/logo-pix-icone-64-green.png" alt="Pix" height="20"/>
                              Nova Cobrança
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- RECEIVER -->
                    <hr>
                    <div class="row">
                      <div class="col text-center mb-4">
                        <qrcode :value="pixCharge.qrCode" :options="{ width: 200, margin:0 }"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center mb-4">
                        <h5>CÓDIGO COPIA E COLA:</h5>
                        <span class="text-muted" style="font-size: 10px">
                          {{pixCharge.qrCode}}
                        </span>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 mb-3">
                        <button type="button" class="btn btn-info btn-sm" @click="copy()">
                          <span class="btn-label"><i class="fas fa-copy"></i></span> Copiar Código
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <card
                  header-classes="bg-transparent"
                  body-classes="px-lg-7"
                  class="card-pricing border-0 text-center mb-4">

                  <!--Header-->
                  <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Crie cobranças PIX para
                    recarregar sua conta!</h4>

                  <!--Body-->
                  <div class="display-2">R$ {{serviceFee}}</div>
                  <span class=" text-muted">por cobrança</span>
                  <ul class="list-unstyled my-4">
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Receba qualquer valor através de um QR Code.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Confira atentamente os dados da cobrança a ser gerada.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Para sua organização, você pode adicionar uma breve identificação à cobrança.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">A compensação ocorrerá poucos instantes após o pagamento da cobrança.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Serviço isento de tarifa e sem limites de horários.</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </card>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
      <div v-if="tableData!=null && tableData.length > 0">
        <div class="card">
          <div class="border-0 card-header">
            <h3 class="mb-0">Histórico</h3>
          </div>
          <el-table :data="tableData"
                    row-key="id"
                    header-row-class-name="thead-light"
                    empty-text="Nenhuma transação encontrada.">
            <el-table-column label="Valor" min-width="135px" prop="value" sortable>
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.value != null">R$ {{toMoney(props.row.value)}}</span>
                      <span v-else>LIVRE</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Status" min-width="130px" prop="status" sortable>
              <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle"
                         :style="'color: #'+statusToColor(props.row.status, props.row.totalPayments)"></i>
                      <span> {{ statusToLabel(props.row.status, props.row.totalPayments) }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Ult. Pag." min-width="135px" prop="lastPaymentValue" sortable>
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.lastPaymentValue != null">R$ {{toMoney(props.row.lastPaymentValue)}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Taxa" min-width="130px" prop="feeValue" sortable>
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.feeValue != null">R$ {{toMoney(props.row.feeValue)}}</span>
                      <span v-else>GRÁTIS</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Vencimento" min-width="156px" prop="dueDate" sortable>
              <template slot-scope="props">
                    <span>
                      <span>{{ props.row.dueDate }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Criado" min-width="156px" prop="created" sortable>
              <template slot-scope="props">
                    <span>
                      <span>{{ props.row.created }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Identificação" min-width="170px" prop="userComment" sortable>
              <template slot-scope="props">
                   <span>
                      <span>{{props.row.userComment}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column class-name="td-actions" min-width="160px">
              <template slot-scope="props">

                <base-button
                  @click.native="showQrCode(props.row)"
                  class="like btn-link"
                  type="info"
                  size="sm"
                  title="Visualizar QrCode"
                  icon>
                  <i class="fas fa-qrcode"></i>
                </base-button>
                <base-button
                  @click.native="printPdf(props.row.id)"
                  class="edit"
                  type="warning"
                  size="sm"
                  title="Exibir PDF / Imprimir"
                  icon>
                  <i class="fas fa-file-pdf"></i>
                  <i class="fas fa-print"></i>
                </base-button>

              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="card-footer d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category small">
                Exibindo de {{ pagination.fromNumber}} a {{ pagination.toNumber }} - Total {{ pagination.totalNumber }}
                registros
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} linhas selecionadas
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.totalNumber"
              v-on:input="loadData">
            </base-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {BasePagination} from '@/components'
  import {
    copyText,
    isNull,
    isNullOrEmpty, isValidCnpj, isValidCpf,
    moneyToFloat,
    notNull,
    notNullOrEmpty, onlyDigits,
    toMoney
  } from "@/util/core.utils"
  import {failWs, postWs} from '@/ws.service'
  import Autocomplete from 'vuejs-auto-complete'
  import {VueMaskFilter} from 'v-mask'
  import clientPaginationMixin from "../../util/tables/PaginatedTables/clientPaginationMixin";
  import { extend } from 'vee-validate';

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Autocomplete,
      extend
    },
    filters: {
      VueMaskFilter
    },

    data() {
      return {
        docsOk: false,
        editableValue: false,
        validated: false,
        generatePix: false,
        name: '',
        socialNumber: '',
        freeFieldMaxLength: 37,
        freeFieldDefaultMsg: '',
        chargeToCreate: {
          value: '',
          freeField: '',
          userComment: ''
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        destinationIdType: '',
        serviceFee: '0,00',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        pixCharge: {
          id: null,
          value: null,
          lastPaymentValue: null,
          feeValue: '',
          qrCode: null,
          dueDate: '',
          errorMessage: '',
          base64: null
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        selectedRows: [],
        alpha_num_space: null,
      }
    },
    created() {
      extend('alpha_num_space', {
        message: field => 'O campo ' + field + ' deve possuir apenas alfanuméricos e espaços.',
        validate: (value) => {
          return /^[A-Za-z0-9 ]+$/.test(value)
        }
      });

      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }

      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
      this.name = localStorage.getItem('name');
      this.socialNumber = localStorage.getItem('socialNumberDisplay');

      let secondNameSpace = this.name.indexOf(" ", this.name.indexOf(" ") + 1);
      let firstNames = secondNameSpace > 0 ? this.name.substr(0, secondNameSpace) : this.name;
      this.freeFieldDefaultMsg = 'De ' + firstNames
      if (this.freeFieldDefaultMsg.length > this.freeFieldMaxLength) {
        this.freeFieldDefaultMsg = this.freeFieldDefaultMsg.substr(0, this.freeFieldMaxLength - 3)
        this.freeFieldDefaultMsg = this.freeFieldDefaultMsg + "..."
      }

      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      notNullOrEmpty,
      isNull,
      callCreateChargeWs() {
        this.generatePix = true
        if (!this.editableValue) {
          this.chargeToCreate.value = "0,00";
        } else if (moneyToFloat(this.chargeToCreate.value) <= 0) {
          this.$swal({
            title: 'Aviso!',
            text: 'O valor deve ser maior que zero.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.generatePix = false
          return
        }

        this.$swal({
          title: 'Confirma o valor ?',
          html: "VALOR: <b>" + (this.editableValue ? ('R$ ' + this.chargeToCreate.value) : 'A definir pelo pagador (LIVRE)') + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callCreateStaticCharge()
          } else {
            this.generatePix = false
          }
        })
      },
      callCreateStaticCharge() {
        postWs("/pix/charge/create-static-charge",
          true, null,
          {
            value: moneyToFloat(this.chargeToCreate.value),
            freeField: isNullOrEmpty(this.chargeToCreate.freeField) ? this.freeFieldDefaultMsg : this.chargeToCreate.freeField,
            userComment: this.chargeToCreate.userComment,
          },
          (response) => {
            this.generatePix = false
            if (response.data.status === 'SUCCESS') {
              this.pixCharge.id = response.data.id
              this.pixCharge.qrCode = response.data.qrCode

              this.loadData()
              return
            }
            this.$swal({
              title: 'Aviso!',
              text: 'Falha ao transferir! Tente mais tarde.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
          this.handlerCatchForInit()
        )
      },
      handlerCatchForInit: function () {
        this.generatePix = false
        return (error) => {
          let text = null
          if (error.response.data.status == 'NO_MOBILE_DEVICE_REGISTERED') {
            text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
          } else if (error.response.data.status == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
            text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
          } else if (error.response.data.status == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' ||
            error.response.data.status == 'BLOCKED_OUT_TRANSACTIONS' ||
            error.response.data.status == 'BLOCKED') {
            text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
          } else if (error.response.data.status == 'SOURCE_USER_INACTIVE') {
            text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
          } else if (error.response.data.status == 'DICT_NOT_FOUND') {
            text = 'Não foi possível encontrar a chave informada no diretório do PIX! Por favor, verifique e tente novamente.'
          } else if (error.response.data.status == 'RECEIVER_EQUALS_PAYER') {
            text = 'Ops. Parece que esta pessoa já possui uma conta em nosso sistema. Por favor realize uma transferência entre contas utilizando o nome de usuário da conta!'
          }
          if (!isNullOrEmpty(text)) {
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          } else {
            this.$swal({
              title: 'Serviço temporariamente indisponível!',
              text: 'Tente novamente mais tarde.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          }
        };
      },
      cancelTransaction() {
        this.clearAll()
      },
      clearAll() {
        this.chargeToCreate.freeField = ''
        this.chargeToCreate.userComment = ''
        this.chargeToCreate.value = '0,00'
        this.editableValue = false

        this.pixCharge.id = null
        this.pixCharge.qrCode = null
      },
      success() {
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = toMoney(moneyToFloat(this.accountBalance))
        localStorage.setItem('accountBalance', this.accountBalance)
        this.clearAll()
        this.$swal({
          title: 'Sucesso!',
          text: 'Valor transferido com sucesso!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          },
          timer: 5000,
          icon: 'success'
        })
      },
      printPdf(id) {
        if (isNullOrEmpty(id)) {
          id = this.pixCharge.id
        }
        postWs("/pix/charge/pdf",
          true,
          {id: id}, null,
          this.successPrintPdf,
          failWs)
      },
      successPrintPdf(response) {
        this.$router.push({
          name: 'ShowPdf',
          params: {pageToBack: '/pages/pix-charge', pdfBase64: response.data.base64}
        })
      },
      showQrCode(pixCharge) {
        this.pixCharge.id = pixCharge.id
        this.pixCharge.value = pixCharge.value
        this.pixCharge.feeValue = pixCharge.feeValue
        this.pixCharge.qrCode = pixCharge.qrCode
        this.pixCharge.dueDate = pixCharge.dueDate
      },
      loadData() {
        postWs("/pix/charge/last-requested-charge",
          true, null,
          {
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status, totalPayments) {
        if (totalPayments > 0) {
          return 'Pago (' + totalPayments + 'x)'
        } else if (status == null
          || 'ACTIVE' == status) {
          return 'Aguard.'
        } else if ('REMOVED_BY_RECEIVER' == status) {
          return 'Rem. Recebedor'
        } else if ('FINISHED' == status) {
          return 'Pronto'
        } else {
          return 'Rem. PSP'
        }
      },
      statusToColor(status, totalPayments) {
        if (totalPayments > 0) {
          return '7ad06b'//green
        } else if (status == null
          || 'ACTIVE' == status) {
          return '8096ff'//blue
        } else if ('REMOVED_BY_RECEIVER' == status) {
          return 'fbc658'//yellow
        } else if ('FINISHED' == status) {
          return '6bccba'//green~blue
        } else {
          return 'ef8157'//red
        }
      },
      copy() {
        let successful = false;
        try {
          successful = copyText(this.pixCharge.qrCode);
        } finally {
          this.$swal({
            title: 'Copiar Código PIX',
            text: (successful ? 'Código PIX copiado com sucesso!' : 'Falha ao copiar Código PIX!'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-' + (successful ? 'success' : 'danger') + ' btn-fill'
            },
            icon: (successful ? 'success' : 'error'),
            timer: 5000
          })
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      }
    }
  }

</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
